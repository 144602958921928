<template>
    <div class="login flex">
        <div class="head flex">
            <img src="../../assets/logo.png" width="28px" alt="logo">
            <el-divider direction="vertical"></el-divider>
            <span>DD VINTAGE REMAKE</span>
        </div>
        <div class="contain flex-between">
            <div class="box-left">
                <h1>DD VINTAGE REMAKE</h1>
                <h2>全新模式，畅快工作</h2>
            </div>
            <div class="box-right flex">
                <h4 class="title">登录</h4>
                <!-- <span class="tips">为您量身定做的商品管理左右手</span> -->
                <div class="input flex">
                    <el-input class="login-input height-fourty" v-model="login.username" placeholder="请输入账号">
                        <i slot="prefix" class="tb-icon-user"></i>
                    </el-input>
                </div>
                <div class="input flex">
                    <el-input class="login-input height-fourty" v-model="login.password" placeholder="请输入密码"
                              type="password">
                        <i slot="prefix" class="tb-icon-password"></i>
                    </el-input>
                </div>
                <div class="input flex" v-if="authCodeShow">
                    <el-input type="number" class="login-input height-fourty" placeholder="请输入验证码"
                              v-model="login.authcode"></el-input>
                    <el-button class="authcode-btn height-fourty" :disabled="sendAuthCode" @click="getAuthCode">
                        {{sendAuthCode ? `${authTime}s后重发` : '获取验证码'}}
                    </el-button>
                </div>
                <el-button type="primary" class="login-btn height-fourty" @click="toLogin">登 录</el-button>
                <div class="login-tips flex-between">
                    <div class="foot_lf">
                        <!-- <img src="../assets/login/icon_caution@3x.png" alt="">
                        <span>{{ loginMsg }}</span> -->
                    </div>
                    <router-link class="forget" :to="{ path: '/getpwd' }">忘记密码</router-link>
                </div>
            </div>
        </div>
        <div class="foot"></div>
    </div>
</template>

<script>
    import $md5 from 'js-md5'

    export default {
        name: 'Login',
        data() {
            return {
                login: {
                    username: '',
                    password: '',
                    authcode: ''
                },
                authTime: 60,
                authCodeShow: false,
                sendAuthCode: false
            }
        },
        methods: {
            getAuthCode() {
                if (!this.login.username) {
                    this.$message.error('请输入用户名')
                    return
                }
                const params = {
                    user_name: this.login.username,
                    type: 1
                }
                const url = 'admin/user/verify/send'
                this.$https.get(url, {params}).then(({code, msg}) => {
                    if (code === 200) {
                        this.sendAuthCode = true
                        this.authTime = 60
                        let authTimeTimer = setInterval(() => {
                            this.authTime--
                            if (this.authTime <= 0) {
                                this.sendAuthCode = false
                                clearInterval(authTimeTimer)
                            }
                        }, 1000)
                    } else {
                        this.$message.error(msg)
                    }
                })
            },
            async toLogin() {
                const {username, password, authcode} = this.login
                if (!username || !password) {
                    this.$message.error('用户名或密码不能为空')
                    return
                }
                const params = {
                    user_name: username,
                    pwd: $md5(password).toUpperCase()
                }
                if (authcode && this.authCodeShow) params.code = authcode
                const url = 'admin/user/login'
                const {code, data, msg} = await this.$https.get(url, {params})
                if (code === 200) {
                    this.$store.commit('setUserInfo', data)
                    this.$router.push('/home')
                } else {
                    if (code === 710)
                        this.authCodeShow = true
                    this.$message.error(msg)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .login {
        width: 100%;
        height: 100%;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;

        .head {
            padding: 0 215px;
            height: 60px;
            line-height: 60px;
            width: 100%;
            align-items: center;
            font-size: 16px;
            border-bottom: 1px solid #D8D8D8;
            font-weight: bold;
        }

        .contain {
            width: 100%;
            flex: 1;
            padding: 0 120px;
            background-image: url('https://ddimage.topboom0912.com/admin_static_img/login.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            box-sizing: border-box;

            .box-left {
                width: 600px;

                h1, h2, p {
                    color: #fff;
                    margin: 0;
                }

                h1 {
                    font-size: 38px;
                    font-weight: 600;
                    padding-bottom: 8px;
                }

                h2 {
                    font-size: 24px;
                    padding-bottom: 18px;
                }

                p {
                    font-size: 16px;
                }
            }

            .box-right {
                flex-direction: column;
                padding: 24px;
                width: 362px;
                min-height: 354px;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 4px;

                .title {
                    margin: 0;
                    padding-bottom: 6px;
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.85);
                    text-align: center;
                }

                .tips {
                    margin: 0;
                    padding-bottom: 24px;
                    color: rgba(0, 0, 0, 0.45);
                    text-align: center;
                }

                .input {
                    margin-bottom: 24px;

                    .login-input {
                        flex: 1;

                        .tb-icon-user, .tb-icon-password {
                            width: 16px;
                            height: 100%;
                            line-height: 40px;
                        }
                    }

                    .authcode-btn {
                        width: 102px;
                        margin-left: 8px;
                    }
                }

                .login-btn {
                    width: 100%;
                }

                .login-tips {
                    padding-top: 16px;

                    .forget {
                        text-decoration: none;
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
            }
        }

        .foot {
            height: 108px;
        }
    }
</style>
